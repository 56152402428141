<template>
  <v-card class="pa-6">
    <v-list nav v-if="!loading">
      <v-list-item
        v-for="classi in classificacoes"
        :key="classi.id"
        @click="goToClassificacao(classi)"
        class="align-stretch "
        style="min-height: 42px; max-height: 42px;"
      >
        <div
          v-if="classi.cor"
          class="my-1"
          style="width: 8px; max-width: 8px; border-radius: 3px; margin-right: 15px;"
          :style="`background-color: ${classi.cor}`"
        ></div>
        <v-list-item-icon
          class="ma-0 mr-3 align-self-center"
          v-if="classi.icone"
        >
          <v-icon v-text="classi.icone"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ classi.descricao }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-if="vueClassificacao">
          <v-icon>
            edit
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <Carregando v-else />
    <v-card-text v-if="!classificacoes.length">
      {{ $tc("class.nenhumaclass") }}
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getClassificacoes,
  putClassificacoes,
} from "@/services/api/classificacoes.api.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaClassificacoes",
  data: () => ({
    loading: true,
    classificacoes: [],
    classificacao: {},
    dialog: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueClassificacao() {
      return this.getAccess("vueClassificacao", "visualizar");
    },
  },
  methods: {
    goToClassificacao(item) {
      if (this.vueClassificacao) {
        this.$store.commit("UPDATE_DYNAMICCRUMB", item.descricao);
        this.$router.push({
          name: "Classificacao",
          params: { classificacaoId: item.id },
        });
      }
    },
    openDialog(classi) {
      this.dialog = true;
      this.classificacao = classi;
    },
    getClassificacoes() {
      this.loading = true;
      this.$Progress.start();
      getClassificacoes()
        .then((response) => {
          this.classificacoes = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getClassificacoes();
  },
};
</script>

<style scoped lang="scss"></style>
